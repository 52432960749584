import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import * as L from 'leaflet';
import { MapContext } from './MapContext';
import { useState } from 'react'

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

const MainSnowreport = React.lazy(() => import('./Snowreport/MainSnowreport'));
const MainAlpinemeteo = React.lazy(() => import('./Alpinemeteo/MainAlpinemeteo'));
const MainAvalanchereport = React.lazy(() => import('./Avalanchereport/MainAvalanchereport'));

const App = () => {
   const [mapState, setMapState] = useState({
      center: [47.2, 11],
      zoom: L.Browser.mobile ? 8 : 9
   });

   dayjs.extend(localeData);
   dayjs.extend(advancedFormat);
   dayjs.locale('de');

   return (
      <Suspense>      
      <MapContext.Provider value={{ mapState, setMapState }}>
         <Router>
            <Routes>
               <Route path="snowreport" exact element={<MainSnowreport />} />
               <Route path="alpinemeteo" exact element={<MainAlpinemeteo />} />
               <Route path="avalanchereport" exact element={<MainAvalanchereport />} />
               <Route path="*" element={<MainSnowreport />} />
            </Routes>
         </Router>
      </MapContext.Provider>
      </Suspense>
   );
};

export default App;